import Kepegawaian from '../views/Kepegawaian';
import Profil from '../views/Profil';
import Sistem from '../views/Sistem';
import Presensi from '../views/Presensi';
import Surat from '../views/Surat';
import Dashboard from '../views/Dashboard/Index';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
	{ path: ['/dashboard'].join('/'), component: Dashboard.Dashboard },

	{ path: ['/kepegawaian'].join('/'), component: Kepegawaian.BiodataPegawai },
	{ path: ['/kepegawaian', 'riwayat'].join('/'), component: Kepegawaian.RiwayatKepegawaian },
	{ path: ['/kepegawaian', 'riwayat', ":id"].join('/'), component: Kepegawaian.DetilRiwayatKepegawaian },
	{ path: ['/kepegawaian', 'riwayat', ":id", "sk-baru"].join('/'), component: Kepegawaian.TambahSuratKeterangan },
	{ path: ['/kepegawaian', 'riwayat', ":id", ":id_sk"].join('/'), component: Kepegawaian.DetilSuratKeterangan },
	{ path: ['/kepegawaian', 'biodata'].join('/'), component: Kepegawaian.BiodataPegawai },
	{ path: ['/kepegawaian', 'biodata', ":id"].join('/'), component: Kepegawaian.DetilBiodataPegawai },
	{ path: ['/kepegawaian', 'arsip'].join('/'), component: Kepegawaian.DaftarArsipPegawai },
	{ path: ['/kepegawaian', 'arsip', ":id", "biodata"].join('/'), component: Kepegawaian.DetilArsipBiodata },
	{ path: ['/kepegawaian', 'arsip', ":id", "riwayat"].join('/'), component: Kepegawaian.DetilArsipRiwayat },
	{ path: ['/kepegawaian', 'arsip', ":id", "sk", ":id_sk"].join('/'), component: Kepegawaian.DetilArsipSK },

	{ path: ['/profil', 'biodata'].join('/'
	), component: Profil.ProfilBiodata },
	{ path: ['/profil', 'kepegawaian'].join('/'), component: Profil.ProfilKepegawaian },
	{ path: ['/profil', 'kepegawaian', ":id_sk"].join('/'), component: Profil.DetilSuratKeterangan },

	{ path: ['/presensi', 'daftar'].join('/'), component: Presensi.DaftarPresensi },
	{ path: ['/presensi', 'daftar', ":id"].join('/'), component: Presensi.DetilPresensi },
	{ path: ['/presensi', 'pegawai'].join('/'), component: Presensi.PresensiPegawai },
	{ path: ['/presensi', 'pegawai', ":id"].join('/'), component: Presensi.DetilPresensiPegawai },

	{ path: ['/surat', 'unit', ":id"].join('/'), component: Surat.SuratUnit },
	{ path: ['/surat', 'surat-anda'].join('/'), component: Surat.SuratAnda },
	{ path: ['/surat', 'surat-masuk'].join('/'), component: Surat.SuratMasuk },
	{ path: ['/surat', 'surat-keluar'].join('/'), component: Surat.SuratKeluar },
	{ path: ['/surat', 'surat-masuk', 'baru'].join('/'), component: Surat.SuratMasukBaru },
	{ path: ['/surat', 'surat-keluar', 'baru'].join('/'), component: Surat.SuratKeluarBaru },
	{ path: ['/surat', 'surat-keluar', 'arsip'].join('/'), component: Surat.SuratKeluarArsip },
	{ path: ['/surat', 'surat-anda', ':ID'].join('/'), component: Surat.SuratAndaID },
	{ path: ['/surat', 'surat-masuk', ':ID'].join('/'), component: Surat.SuratMasukID },
	{ path: ['/surat', 'surat-keluar', ':ID'].join('/'), component: Surat.SuratKeluarID },
	{ path: ['/surat', 'daftar-surat', ':ID'].join('/'), component: Surat.DaftarSuratID },
	{ path: ['/surat', 'surat-masuk', ':ID', 'perbarui'].join('/'), component: Surat.SuratMasukEdit },
	{ path: ['/surat', 'surat-keluar', ':ID', 'perbarui'].join('/'), component: Surat.SuratKeluarEdit },
	{ path: ['/surat', 'daftar-surat'].join('/'), component: Surat.DaftarSurat },
	
	{ path: ['/pengaturan-sistem', 'akun-pegawai'].join('/'), component: Sistem.DaftarAkunPegawai },
	{ path: ['/pengaturan-sistem', 'akun-pegawai', 'tambah'].join('/'), component: Sistem.TambahPegawai },
	{ path: ['/pengaturan-sistem', 'akun-pegawai', ":id"].join('/'), component: Sistem.DetilAkunPegawai },
	{ path: ['/pengaturan-sistem', 'pengelolaan-unit',].join('/'), component: Sistem.DaftarPengelolaan },
	{ path: ['/pengaturan-sistem', 'pengelolaan-unit', 'tambah-unit'].join('/'), component: Sistem.TambahUnit },
	{ path: ['/pengaturan-sistem', 'pengelolaan-unit', ':id'].join('/'), component: Sistem.DetilPengelolaan },
	{ path: ['/pengaturan-sistem', 'pengelolaan-yayasan',].join('/'), component: Sistem.DaftarPengelolaanYayasan },
	{ path: ['/pengaturan-sistem', 'pengelolaan-yayasan', ':id'].join('/'), component: Sistem.DetilPengelolaan },
	{ path: ['/pengaturan-sistem', 'penguncian-akses'].join('/'), component: Sistem.PenguncianAkses },
	{ path: ['/pengaturan-sistem', 'template-sk'].join('/'), component: Sistem.TemplateSK },
	{ path: ['/pengaturan-sistem', 'template-sk', 'baru'].join('/'), component: Sistem.TambahTemplateSK },
	{ path: ['/pengaturan-sistem', 'template-sk', ':id'].join('/'), component: Sistem.DetilTemplateSK },
	{ path: ['/pengaturan-sistem', 'pengaturan-presensi'].join('/'), component: Sistem.PengaturanPresensi },
	{ path: ['/pengaturan-sistem', 'pengaturan-variabel'].join('/'), component: Sistem.PengaturanVariabel },
];