import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Header, Image } from 'semantic-ui-react';
import styled from 'styled-components';
import bkgpattern from '../../assets/bkgpattern.svg'
import logo_sekolah from '../../assets/logo_sekolah.png'
import { UserContext } from '../../context';
import ForgetForm from './ForgetForm';
import LoginForm from './LoginForm';

const MainContainer = styled.div`
	display: grid; 
	grid-template-columns: 1fr 1fr;
	
	@media only screen and (max-width: 768px) {
		grid-template-columns: 1fr;
		grid-gap: 2em;
	}
`
const ContainerBGRepeat = styled.div`
	witdh: 100%;
	height: 100vh;
	background-color: #FAFEFB;
	background-image: url(${bkgpattern});
	background-repeat: repeat;
	background-size: 400px;

	padding: 5em;
	display: flex;
	align-items: center;

	div {
		display: grid;
		grid-template-rows: 1fr;
		grid-gap: 1em;
	}
`
const ContainerInput = styled.div`
	width: 20em;
	justify-self: center;
	align-self: center;
`

class Login extends Component {
	state = {
		stepValue: "FormLogin",
	}

	handleClick = (e, props) => {
		const { name } = props
		
		switch(name){
			case "lupa-password": { this.setState({stepValue: "FormLupaPassword"}); break;}
			case "kembali": { this.setState({stepValue: "FormLogin"}); break;}
			default:
		}
	}

	render() {
		const { stepValue } = this.state
		const { is_login } = this.context

		const search = new URLSearchParams(this.props?.location?.search)
		const referer = search.get('referer') || '/';

		if (is_login) { return <Redirect to={referer} /> }

		return <MainContainer>
			<ContainerBGRepeat>
				<div>
					<div><Image src={logo_sekolah}/></div>
					<div style={{paddingLeft: ".62em"}}><Header as="h1" color="green">SIM-YNH</Header></div>
					<div style={{paddingLeft: ".62em"}}><Header as="h2">
						Sistem Informasi Manajemen <br />
						Yayasan Nur Hidayah <br />
						Surakarta <br />
					</Header></div>
				</div>
			</ContainerBGRepeat>
			<ContainerInput>
				{stepValue === "FormLogin" && <LoginForm history={this.props.history} handleClick={this.handleClick} />}
				{stepValue === "FormLupaPassword" && <ForgetForm handleClick={this.handleClick}/>}
			</ContainerInput>
		</MainContainer>
	}
}

Login.contextType = UserContext

export default Login;