import React, { Component } from 'react';
import styled from "styled-components";
import { Popup, Accordion, Button, Card, Checkbox, Divider, Dropdown, Feed, Form, Header, Icon, Image, Input, Label, Menu, Message, Segment, Step, Modal, Statistic, Progress, Grid, Placeholder } from "semantic-ui-react"

const ContainerFlex = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 1200px;
`
const ContainerFlex2 = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1em;
	width: 33.3%;
	> div {
		padding: 1em;
	}
`
const SizeDiv = styled.div`
	display: grid;
	justify-items: center;
`
const countryOptions = [
  { key: 'af', value: 'af', flag: 'af', text: 'Afghanistan' },
  { key: 'ax', value: 'ax', flag: 'ax', text: 'Aland Islands' },
  { key: 'al', value: 'al', flag: 'al', text: 'Albania' },
  { key: 'dz', value: 'dz', flag: 'dz', text: 'Algeria' },
  { key: 'as', value: 'as', flag: 'as', text: 'American Samoa' },
  { key: 'ad', value: 'ad', flag: 'ad', text: 'Andorra' },
  { key: 'ao', value: 'ao', flag: 'ao', text: 'Angola' },
  { key: 'ai', value: 'ai', flag: 'ai', text: 'Anguilla' },
  { key: 'ag', value: 'ag', flag: 'ag', text: 'Antigua' },
  { key: 'ar', value: 'ar', flag: 'ar', text: 'Argentina' },
  { key: 'am', value: 'am', flag: 'am', text: 'Armenia' },
  { key: 'aw', value: 'aw', flag: 'aw', text: 'Aruba' },
  { key: 'au', value: 'au', flag: 'au', text: 'Australia' },
  { key: 'at', value: 'at', flag: 'at', text: 'Austria' },
  { key: 'az', value: 'az', flag: 'az', text: 'Azerbaijan' },
  { key: 'bs', value: 'bs', flag: 'bs', text: 'Bahamas' },
  { key: 'bh', value: 'bh', flag: 'bh', text: 'Bahrain' },
  { key: 'bd', value: 'bd', flag: 'bd', text: 'Bangladesh' },
  { key: 'bb', value: 'bb', flag: 'bb', text: 'Barbados' },
  { key: 'by', value: 'by', flag: 'by', text: 'Belarus' },
  { key: 'be', value: 'be', flag: 'be', text: 'Belgium' },
  { key: 'bz', value: 'bz', flag: 'bz', text: 'Belize' },
  { key: 'bj', value: 'bj', flag: 'bj', text: 'Benin' },
]
const tagOptions = [
  {
    key: 'Important',
    text: 'Important',
    value: 'Important',
    label: { color: 'red', empty: true, circular: true },
  },
  {
    key: 'Announcement',
    text: 'Announcement',
    value: 'Announcement',
    label: { color: 'blue', empty: true, circular: true },
  },
  {
    key: 'Cannot Fix',
    text: 'Cannot Fix',
    value: 'Cannot Fix',
    label: { color: 'black', empty: true, circular: true },
  },
  {
    key: 'News',
    text: 'News',
    value: 'News',
    label: { color: 'purple', empty: true, circular: true },
  },
  {
    key: 'Enhancement',
    text: 'Enhancement',
    value: 'Enhancement',
    label: { color: 'orange', empty: true, circular: true },
  },
  {
    key: 'Change Declined',
    text: 'Change Declined',
    value: 'Change Declined',
    label: { empty: true, circular: true },
  },
  {
    key: 'Off Topic',
    text: 'Off Topic',
    value: 'Off Topic',
    label: { color: 'yellow', empty: true, circular: true },
  },
  {
    key: 'Interesting',
    text: 'Interesting',
    value: 'Interesting',
    label: { color: 'pink', empty: true, circular: true },
  },
  {
    key: 'Discussion',
    text: 'Discussion',
    value: 'Discussion',
    label: { color: 'green', empty: true, circular: true },
  },
]
const ColorForm = (
  <Form>
    <Form.Group grouped>
      <Form.Checkbox label='Red' name='color' value='red' />
      <Form.Checkbox label='Orange' name='color' value='orange' />
      <Form.Checkbox label='Green' name='color' value='green' />
      <Form.Checkbox label='Blue' name='color' value='blue' />
    </Form.Group>
  </Form>
)
const SizeForm = (
  <Form>
    <Form.Group grouped>
      <Form.Radio label='Small' name='size' type='radio' value='small' />
      <Form.Radio label='Medium' name='size' type='radio' value='medium' />
      <Form.Radio label='Large' name='size' type='radio' value='large' />
      <Form.Radio label='X-Large' name='size' type='radio' value='x-large' />
    </Form.Group>
  </Form>
)

const description = [
  'Amy is a violinist with 2 years experience in the wedding industry.',
  'She enjoys the outdoors and currently resides in upstate New York.',
].join(' ')

class CobaCoba extends Component {
	state ={
		activeItem: "inbox",
		activeIndex: 0,
		openModal: false,
		percent: 33
	}

	handleClick = (e, {index}) => {
		this.setState({activeIndex: index});
	}
	handleItemClick = (e, {name}) => {
		this.setState({activeItem: name});
	}
	increment = () =>
    this.setState((prevState) => ({
		percent: prevState.percent >= 100 ? 0 : prevState.percent + 20,
	}))

	render() {
		const { activeItem, activeIndex, openModal } = this.state

		return (
			<div style={{width: "100%"}}>
				<SizeDiv>
					<ContainerFlex>
						<ContainerFlex2>
							<div><Header as="h3">Dropdown</Header></div>
							<div>
								<Dropdown
									placeholder='Select Country'
									fluid
									search
									selection
									options={countryOptions}
								/>
							</div>
							<div style={{paddingBottom: 0, paddingTop: 0}}><Divider /></div>
							<div>
								<Dropdown
									text='Filter Posts'
									icon='filter'
									floating
									labeled
									button
									className='icon'
								>
									<Dropdown.Menu>
										<Input icon='search' iconPosition='left' className='search' />
										<Dropdown.Divider />
										<Dropdown.Header icon='tags' content='Tag Label' />
										<Dropdown.Menu scrolling>
											{tagOptions.map((option) => (
												<Dropdown.Item key={option.value} {...option} />
											))}
										</Dropdown.Menu>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<Dropdown text='Dropdown Biasa' options={[
									{ key: 1, text: 'Choice 1', value: 1 },
									{ key: 2, text: 'Choice 2', value: 2, disabled: true },
									{ key: 3, text: 'Choice 3', value: 3 },
								]} />
							</div>
							<div>
							<a><Dropdown text='Dropdown Biasa Element <a>' options={[
									{ key: 1, text: 'Choice 1', value: 1 },
									{ key: 2, text: 'Choice 2', value: 2, disabled: true },
									{ key: 3, text: 'Choice 3', value: 3 },
								]} /></a>
							</div>
						</ContainerFlex2>
						<ContainerFlex2>
							<div><Header as="h3">Feed</Header></div>
							<div>
								<Feed>
									<Feed.Event
										image='https://react.semantic-ui.com/images/avatar/small/elliot.jpg'
										content='You added Elliot Fu to the group Coworkers'
									/>
									<Feed.Event>
										<Feed.Label image='https://react.semantic-ui.com/images/avatar/small/jenny.jpg' />
										<Feed.Content content='You added Elliot Fu to the group Coworkers' />
									</Feed.Event>
									<Feed.Event>
										<Feed.Label image='https://react.semantic-ui.com/images/avatar/small/helen.jpg' />
										<Feed.Content content='You added Elliot Fu to the group Coworkers' />
									</Feed.Event>
								</Feed>
							</div>
						</ContainerFlex2>
						<ContainerFlex2>
							<div><Header as="h3">Label</Header></div>
							<div>
								<Label image>
									<Image src='https://react.semantic-ui.com/images/avatar/small/ade.jpg' />
									Adrienne
									<Icon name='delete' />
								</Label>
								<Label as='a' content='23 New' icon='mail' color="teal"/>
							</div>
							<div><Label as='a' content='23 New' icon='mail'/></div>
							<div>
								<Label as='a' tag>
									Featured
								</Label>
							</div>
							<div>
								<Label as='a' color='blue' image>
									<img src='https://react.semantic-ui.com/images/avatar/small/veronika.jpg' />
									Veronika
									<Label.Detail>Friend</Label.Detail>
								</Label>
								<Label as='a' color='teal' image>
									<img src='https://react.semantic-ui.com/images/avatar/small/jenny.jpg' />
									Jenny
									<Label.Detail>Friend</Label.Detail>
								</Label>
								<Label as='a' color='yellow' image>
									<img src='https://react.semantic-ui.com/images/avatar/small/christian.jpg' />
									Christian
									<Label.Detail>Co-worker</Label.Detail>
								</Label>
							</div>
							<div>
								<Label as='a' style={{marginBottom :".5em"}}>Standard</Label>
								<Label as='a' style={{marginBottom:".5em"}} color='red' size="mini">
									Red Mini
								</Label>
								<Label as='a' style={{marginBottom:".5em"}} color='orange' size="tiny">
									Orange Tiny
								</Label>
								<Label as='a' style={{marginBottom:".5em"}} color='yellow' size="small">
									Yellow Small
								</Label>
								<Label as='a' style={{marginBottom:".5em"}} color='olive'>
									Olive Default
								</Label>
								<Label as='a' style={{marginBottom:".5em"}} color='green' size="medium">
									Green Medium
								</Label>
								<Label as='a' style={{marginBottom:".5em"}} color='teal' size="large">
									Teal Large
								</Label>
								<Label as='a' style={{marginBottom:".5em"}} color='blue' size="big">
									Blue Big
								</Label>
								<Label as='a' style={{marginBottom:".5em"}} color='violet' size="large">
									Violet Large
								</Label>
								<Label as='a' style={{marginBottom:".5em"}} color='purple' size="huge">
									Purple Huge
								</Label>
								<Label as='a' style={{marginBottom:".5em"}} color='pink' size="massive">
									Pink Massive
								</Label>
								<Label as='a' style={{marginBottom:".5em"}} color='brown' size="small">
									Brown Small
								</Label>
								<Label as='a' style={{marginBottom:".5em"}} color='grey' size="big">
									Grey Tiny
								</Label>
								<Label as='a' style={{marginBottom:".5em"}} color='black' size="huge">
									Black Mini
								</Label>
							</div>
						</ContainerFlex2>
						<ContainerFlex2>
							<div><Header as="h3">Accordion</Header></div>
							<div>
								<Accordion as={Menu} vertical>
									<Menu.Item>
										<Accordion.Title
											active={activeIndex === 0}
											content='Size'
											index={0}
											onClick={this.handleClick}
										/>
										<Accordion.Content active={activeIndex === 0} content={SizeForm} />
									</Menu.Item>

									<Menu.Item>
										<Accordion.Title
											active={activeIndex === 1}
											content='Colors'
											index={1}
											onClick={this.handleClick}
										/>
										<Accordion.Content active={activeIndex === 1} content={ColorForm} />
									</Menu.Item>
								</Accordion>
							</div>
							<div>
								<Accordion>
									<Accordion.Title
										active={activeIndex === 0}
										index={0}
										onClick={this.handleClick}
									>
										<Icon name='dropdown' />
										What is a dog?
									</Accordion.Title>
									<Accordion.Content active={activeIndex === 0}>
										<p>
											A dog is a type of domesticated animal. Known for its loyalty and
											faithfulness, it can be found as a welcome guest in many households
											across the world.
										</p>
									</Accordion.Content>

									<Accordion.Title
										active={activeIndex === 1}
										index={1}
										onClick={this.handleClick}
									>
										<Icon name='dropdown' />
										What kinds of dogs are there?
									</Accordion.Title>
									<Accordion.Content active={activeIndex === 1}>
										<p>
											There are many breeds of dogs. Each breed varies in size and
											temperament. Owners often select a breed of dog that they find to be
											compatible with their own lifestyle and desires from a companion.
										</p>
									</Accordion.Content>

									<Accordion.Title
										active={activeIndex === 2}
										index={2}
										onClick={this.handleClick}
									>
										<Icon name='dropdown' />
										How do you acquire a dog?
									</Accordion.Title>
									<Accordion.Content active={activeIndex === 2}>
										<p>
											Three common ways for a prospective owner to acquire a dog is from
											pet shops, private owners, or shelters.
										</p>
										<p>
											A pet shop may be the most convenient way to buy a dog. Buying a dog
											from a private owner allows you to assess the pedigree and
											upbringing of your dog before choosing to take it home. Lastly,
											finding your dog from a shelter, helps give a good home to a dog who
											may not find one so readily.
										</p>
									</Accordion.Content>
								</Accordion>
							</div>
						</ContainerFlex2>
						<ContainerFlex2>
							<div><Header as="h3">Button</Header></div>
							<div><Button>Click Here</Button></div>
							<div>
								<Button content='Primary' primary />
								<Button content='Secondary' secondary />
							</div>
							<div>
								<Button animated style={{margin: ".1em"}}>
									<Button.Content visible>Next</Button.Content>
									<Button.Content hidden>
										<Icon name='arrow right' />
									</Button.Content>
								</Button>
								<Button animated='vertical' style={{margin: ".1em"}}>
									<Button.Content hidden>Shop</Button.Content>
									<Button.Content visible>
										<Icon name='shop' />
									</Button.Content>
								</Button>
								<Button animated='fade' style={{margin: ".1em"}}>
									<Button.Content visible>Sign-up for a Pro account</Button.Content>
									<Button.Content hidden>$12.99 a month</Button.Content>
								</Button>
							</div>
							<div>
								<Button.Group>
									<Button basic color='red'>
										One
									</Button>
									<Button basic color='green'>
										Two
									</Button>
									<Button basic color='blue'>
										Three
									</Button>
								</Button.Group>
							</div>
							<div style={{display: "grid", gridGap: ".5em", gridTemplateColumns: "repeat(3, auto)"}}>
								<Button basic>Standard</Button>
								<Button basic color='red'>
									Red
								</Button>
								<Button basic color='orange'>
									Orange
								</Button>
								<Button basic color='yellow'>
									Yellow
								</Button>
								<Button basic color='olive'>
									Olive
								</Button>
								<Button basic color='green'>
									Green
								</Button>
								<Button basic color='teal'>
									Teal
								</Button>
								<Button basic color='blue'>
									Blue
								</Button>
								<Button basic color='violet'>
									Violet
								</Button>
								<Button basic color='purple'>
									Purple
								</Button>
								<Button basic color='pink'>
									Pink
								</Button>
								<Button basic color='brown'>
									Brown
								</Button>
								<Button basic color='grey'>
									Grey
								</Button>
								<Button basic color='black'>
									Black
								</Button>
							</div>
							<div style={{display: "grid", gridGap: ".5em", gridTemplateColumns: "repeat(3, auto)"}}>
								<Button>Standard</Button>
								<Button color='red'>
									Red
								</Button>
								<Button color='orange'>
									Orange
								</Button>
								<Button color='yellow'>
									Yellow
								</Button>
								<Button color='olive'>
									Olive
								</Button>
								<Button color='green'>
									Green
								</Button>
								<Button color='teal'>
									Teal
								</Button>
								<Button color='blue'>
									Blue
								</Button>
								<Button color='violet'>
									Violet
								</Button>
								<Button color='purple'>
									Purple
								</Button>
								<Button color='pink'>
									Pink
								</Button>
								<Button color='brown'>
									Brown
								</Button>
								<Button color='grey'>
									Grey
								</Button>
								<Button color='black'>
									Black
								</Button>
							</div>
							<div>
								<Button style={{margin: ".5em"}} Icon>
									<Icon name='world' />
								</Button>
								<Button style={{margin: ".5em"}} Icon color='red'>
									<Icon name='world' />
								</Button>
								<Button style={{margin: ".5em"}} Icon color='orange'>
									<Icon name='world' />
								</Button>
								<Button style={{margin: ".5em"}} Icon color='yellow'>
									<Icon name='world' />
								</Button>
								<Button style={{margin: ".5em"}} Icon color='olive'>
									<Icon name='world' />
								</Button>
								<Button style={{margin: ".5em"}} Icon color='green'>
									<Icon name='world' />
								</Button>
								<Button style={{margin: ".5em"}} Icon color='teal'>
									<Icon name='world' />
								</Button>
								<Button style={{margin: ".5em"}} Icon color='blue'>
									<Icon name='world' />
								</Button>
								<Button style={{margin: ".5em"}} Icon color='violet'>
									<Icon name='world' />
								</Button>
								<Button style={{margin: ".5em"}} Icon color='purple'>
									<Icon name='world' />
								</Button>
								<Button style={{margin: ".5em"}} Icon color='pink'>
									<Icon name='world' />
								</Button>
								<Button style={{margin: ".5em"}} Icon color='brown'>
									<Icon name='world' />
								</Button>
								<Button style={{margin: ".5em"}} Icon color='grey'>
									<Icon name='world' />
								</Button>
								<Button style={{margin: ".5em"}} Icon color='black'>
									<Icon name='world' />
								</Button>
							</div>
							<div style={{display: "grid", gridGap: ".5em", gridTemplateColumns: "repeat(3, auto)"}}>
								<Button inverted>Standard</Button>
								<Button inverted color='red'>
									Red
								</Button>
								<Button inverted color='orange'>
									Orange
								</Button>
								<Button inverted color='yellow'>
									Yellow
								</Button>
								<Button inverted color='olive'>
									Olive
								</Button>
								<Button inverted color='green'>
									Green
								</Button>
								<Button inverted color='teal'>
									Teal
								</Button>
								<Button inverted color='blue'>
									Blue
								</Button>
								<Button inverted color='violet'>
									Violet
								</Button>
								<Button inverted color='purple'>
									Purple
								</Button>
								<Button inverted color='pink'>
									Pink
								</Button>
								<Button inverted color='brown'>
									Brown
								</Button>
								<Button inverted color='grey'>
									Grey
								</Button>
								<Button inverted color='black'>
									Black
								</Button>
							</div>
							<div>
								<Button style={{margin: ".5em"}} size="mini"><Icon name="edit"/> Mini</Button>
								<Button style={{margin: ".5em"}} size="tiny"><Icon name="edit"/> Tiny</Button>
								<Button style={{margin: ".5em"}} size="small"><Icon name="edit"/> Perbarui</Button>
								<Button style={{margin: ".5em"}} size="medium"><Icon name="edit"/> Medium</Button>
								<Button style={{margin: ".5em"}} size="large"><Icon name="edit"/> Large</Button>
								<Button style={{margin: ".5em"}} size="big"><Icon name="edit"/> Big</Button>
								<Button style={{margin: ".5em"}} size="huge"><Icon name="edit"/> Huge</Button>
								<Button style={{margin: ".5em"}} size="massive"><Icon name="edit"/> Massive</Button>
							</div>
						</ContainerFlex2>
						<ContainerFlex2>
							<div><Header as="h3">Input</Header></div>
							<div>
								<Input fluid action={{
									color: 'teal',
									content: 'Search',
								}} placeholder='Search...' />
							</div>
							<div>
								<Input fluid placeholder='Input biasa'/>
							</div>
							<div style={{display: "flex"}}>
								<Input style={{marginRight: "1em"}} placeholder='Input biasa'/>
								<Button content="Submit"/>
							</div>
							<div><Input placeholder='Input disabled' fluid disabled /></div>
							<div><Input fluid label='http://' icon="search" iconPosition="right" placeholder='mysite.com' /></div>
							<div>
								<Input
									fluid
									icon='tags'
									iconPosition='left'
									label={{ tag: true, content: 'Add Tag' }}
									labelPosition='right'
									placeholder='Enter tags'
								/>
							</div>
						</ContainerFlex2>
						<ContainerFlex2>
							<div><Header as="h3">Message</Header></div>
							<div>
								<Message size='small'>This is a small message.</Message>
							</div>
							<div>
								<Message
									warning
									header='There was some errors with your submission'
									list={[
										'You must include both a upper and lower case letters in your password.',
										'You need to select your home country.',
									]}
								/>
								<Message
									positive
									header='You must register before you can do that!'
									content='Visit our registration page, then try again.'
								/>
								<Message
									info
									header='You must register before you can do that!'
									content='Visit our registration page, then try again.'
								/>
								<Message
									error
									header='You must register before you can do that!'
									content='Visit our registration page, then try again.'
								/>
							</div>
						</ContainerFlex2>
						<ContainerFlex2>
							<div><Header as="h3">Segment</Header></div>
							<div>
								<Segment stacked>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis animi eaque explicabo, deserunt at a excepturi molestias unde dicta veniam similique ullam laboriosam? Corporis dicta est eveniet dolores reprehenderit nesciunt illum voluptatibus in corrupti reiciendis sed, officiis doloremque, odio modi ipsam. Sint ullam debitis totam eligendi quibusdam saepe rerum impedit. Amet sapiente et recusandae esse iure? Perferendis dolor ex velit.</Segment>
							</div>
							<div>
								<Segment raised></Segment>
							</div>
							<div>
							<Segment.Group>
								<Segment></Segment>
								<Segment></Segment>
								<Segment></Segment>
								<Segment></Segment>
								<Segment></Segment>
							</Segment.Group>
							</div>
						</ContainerFlex2>
						<ContainerFlex2>
							<div><Header as="h3">Step</Header></div>
							<div>
								<Step.Group vertical>
									<Step completed>
										<Icon name='truck' />
										<Step.Content>
											<Step.Title>Shipping</Step.Title>
											<Step.Description>Choose your shipping options</Step.Description>
										</Step.Content>
									</Step>

									<Step completed>
										<Icon name='payment' />
										<Step.Content>
											<Step.Title>Billing</Step.Title>
											<Step.Description>Enter billing information</Step.Description>
										</Step.Content>
									</Step>
									<Step active>
										<Icon name='info' />
										<Step.Content>
											<Step.Title>Confirm Order</Step.Title>
										</Step.Content>
									</Step>
								</Step.Group>
							</div>
						</ContainerFlex2>
						<ContainerFlex2>
							<div><Header as="h3">Menu</Header></div>
							<div>
								<Menu vertical fluid>
									<Menu.Item
										name='inbox'
										active={activeItem === 'inbox'}
										onClick={this.handleItemClick}
									>
										<Label color='green'>1</Label>
										Inbox
									</Menu.Item>
									<Menu.Item
										name='trash'
										active={activeItem === 'trash'}
										onClick={this.handleItemClick}
									>
										<Label>1</Label>
										Trash
									</Menu.Item>
									<Menu.Item>
										<Input icon='search' placeholder='Search mail...' />
									</Menu.Item>
								</Menu>
							</div>
							<div>
								<Menu attached='top' tabular>
									<Menu.Item
										name='inbox'
										active={activeItem === 'inbox'}
										onClick={this.handleItemClick}
									/>
									<Menu.Item
										name='starred'
										active={activeItem === 'starred'}
										onClick={this.handleItemClick}
									/>
									<Menu.Item
										name='trash'
										active={activeItem === 'trash'}
										onClick={this.handleItemClick}
									/>
								</Menu>
							</div>
							<div>
								<Menu secondary vertical fluid>
									<Menu.Item
										name='inbox'
										active={activeItem === 'inbox'}
										onClick={this.handleItemClick}
									/>
									<Menu.Item
										name='starred'
										active={activeItem === 'Starred'}
										onClick={this.handleItemClick}
									/>
									<Menu.Item
										name='trash'
										active={activeItem === 'trash'}
										onClick={this.handleItemClick}
									/>
								</Menu>
							</div>
							<div>
								<Menu pointing secondary vertical fluid>
									<Menu.Item
										name='inbox'
										active={activeItem === 'inbox'}
										onClick={this.handleItemClick}
									/>
									<Menu.Item
										name='starred'
										active={activeItem === 'starred'}
										onClick={this.handleItemClick}
									/>
									<Menu.Item
										name='trash'
										active={activeItem === 'trash'}
										onClick={this.handleItemClick}
									/>
								</Menu>
							</div>
						</ContainerFlex2>
						<ContainerFlex2>
							<div><Header as="h3">Card</Header></div>
							<div>
								<Card>
									<Card.Content header='About Amy' />
									<Card.Content description={description} />
								</Card>
							</div>
							<div>
								<Card>
									<Image src='https://react.semantic-ui.com/images/avatar/large/matthew.png' wrapped ui={false} />
									<Card.Content>
										<Card.Header>Matthew</Card.Header>
										<Card.Meta>
											<span className='date'>Joined in 2015</span>
										</Card.Meta>
										<Card.Description>
											Matthew is a musician living in Nashville.
										</Card.Description>
									</Card.Content>
									<Card.Content extra>
										<a href="http://localhost:3001/">
											<Icon name='user' />
											22 Friends
										</a>
									</Card.Content>
								</Card>
							</div>
						</ContainerFlex2>
						<ContainerFlex2>
							<div><Header as="h3">Check Box</Header></div>
							<div><Checkbox label={<label>Make my profile visible</label>} /></div>
							<div><Checkbox toggle /></div>
							<div><Checkbox slider /></div>
						</ContainerFlex2>
						<ContainerFlex2>
							<div><Header as="h3">Divider</Header></div>
							<div><Divider /></div>
						</ContainerFlex2>
						<ContainerFlex2>
							<div><Header as="h3">Form</Header></div>
							<div>
								<Form>
									<Form.Field>
										<label>Input Nama</label>
										<Input fluid/>
									</Form.Field>
									<Form.Field>
										<label>Teks Nama</label>
										<p>Bayu Oscar Dela Saputra</p>
									</Form.Field>
								</Form>
							</div>
						</ContainerFlex2>
						<ContainerFlex2>
							<div><Header as="h3">Popup</Header></div>
							<div>
								<Popup content='Add users to your feed' trigger={<Button icon='add' />} />
							</div>
						</ContainerFlex2>
						<ContainerFlex2>
							<div><Header as="h3">Modal</Header></div>
							<div>
								<Modal
									onClose={() => this.setState({openModal: false})}
									onOpen={() => this.setState({openModal: true})}
									open={openModal}
									size='small'
									trigger={<Button>Basic Modal</Button>}
								>
									<Header icon>
										<Icon name='archive' />
										Archive Old Messages
									</Header>
									<Modal.Content>
										<p>
											Your inbox is getting full, would you like us to enable automatic
											archiving of old messages?
										</p>
									</Modal.Content>
									<Modal.Actions>
										<Button basic color='red' inverted onClick={() => this.setState({openModal: false})}>
											<Icon name='remove' /> No
										</Button>
										<Button color='green' inverted onClick={() => this.setState({openModal: false})}>
											<Icon name='checkmark' /> Yes
										</Button>
									</Modal.Actions>
								</Modal>
							</div>
						</ContainerFlex2>
						<ContainerFlex2>
							<div><Header as="h3">Header</Header></div>
							<div style={{display: "grid", gridGap: ".5em"}}>
								<Header as="h1">
									Header
									<Header.Subheader>Sub Header</Header.Subheader>
								</Header>
							</div>
							<div style={{display: "grid", gridGap: ".5em"}}>
								<Header as="h2">
									Header
									<Header.Subheader>Sub Header</Header.Subheader>
								</Header>
							</div>
							<div style={{display: "grid", gridGap: ".5em"}}>
								<Header as="h3">
									Header
									<Header.Subheader>Sub Header</Header.Subheader>
								</Header>
							</div>
							<div style={{display: "grid", gridGap: ".5em"}}>
								<Header as="h4">
									Header
									<Header.Subheader>Sub Header</Header.Subheader>
								</Header>
							</div>
							<div style={{display: "grid", gridGap: ".5em"}}>
								<Header as="h5">
									Header
									<Header.Subheader>Sub Header</Header.Subheader>
								</Header>
							</div>
							<div style={{display: "grid", gridGap: ".5em"}}>
								<Header as="h6">
									Header
									<Header.Subheader>Sub Header</Header.Subheader>
								</Header>
							</div>
						</ContainerFlex2>
						<ContainerFlex2>
							<div><Header as="h3">{"<p> / Paragraf"}</Header></div>
							<div>
								<p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Id aliquid assumenda officiis neque sequi beatae?</p>
								<p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptate cupiditate quo iste possimus labore et aliquam nobis itaque odio dignissimos laborum, eligendi perferendis, temporibus dolorum iusto a modi, 
									qui eos provident doloribus! <br /> Adipisci <a href="http://localhost:3001/">quibusdam numquam eius</a> deserunt eum expedita in nihil neque architecto vel non, repudiandae saepe necessitatibus, ea, possimus dignissimos dolorum dolorem! Sequi et ipsam reiciendis qui iusto eius quis 
									maiores tempora deserunt voluptates eligendi nulla, at placeat totam voluptatibus inventore.
								</p>
							</div>
						</ContainerFlex2>
						<ContainerFlex2>
							<div><Header as="h3">Statistic</Header></div>
							<div>
								<Statistic size="tiny">
									<Statistic.Label>Sel Nilai Masih Kosong</Statistic.Label>
									<Statistic.Value>100%</Statistic.Value>
								</Statistic>
							</div>
							<div>
								<Statistic>
									<Statistic.Value>22</Statistic.Value>
									<Statistic.Label>Normal</Statistic.Label>
								</Statistic>
							</div>
							<div>
								<Statistic size="mini">
									<Statistic.Value>22</Statistic.Value>
									<Statistic.Label>Mini</Statistic.Label>
								</Statistic>
							</div>
							<div>
								<Statistic size="tiny">
									<Statistic.Value>31,200</Statistic.Value>
									<Statistic.Label>Tiny</Statistic.Label>
								</Statistic>
							</div>
							<div>
								<Statistic size="small">
									<Statistic.Value>344</Statistic.Value>
									<Statistic.Label>Small</Statistic.Label>
								</Statistic>
							</div>
							<div>
								<Statistic size="large">
									<Statistic.Label>Large</Statistic.Label>
									<Statistic.Value>6732</Statistic.Value>
								</Statistic>
							</div>
							<div>
								<Statistic size="huge">
									<Statistic.Value>2</Statistic.Value>
									<Statistic.Label>Huge</Statistic.Label>
								</Statistic>
							</div>
						</ContainerFlex2>
						<ContainerFlex2>
							<div><Header as="h3">Progress</Header></div>
							<div>
								<Progress percent={this.state.percent} indicating />
								<Button onClick={this.increment}>Increment</Button>
							</div>
						</ContainerFlex2>
						<ContainerFlex2>
							<div><Header as="h3">Placeholder</Header></div>
							<div>
								<Grid columns={1} stackable>
									<Grid.Column>
										<Segment raised>
											<Placeholder>
												<Placeholder.Header image>
													<Placeholder.Line />
													<Placeholder.Line />
												</Placeholder.Header>
												<Placeholder.Paragraph>
													<Placeholder.Line length='medium' />
													<Placeholder.Line length='short' />
												</Placeholder.Paragraph>
											</Placeholder>
										</Segment>
									</Grid.Column>

									<Grid.Column>
										<Segment raised>
											<Placeholder>
												<Placeholder.Header image>
													<Placeholder.Line />
													<Placeholder.Line />
												</Placeholder.Header>
												<Placeholder.Paragraph>
													<Placeholder.Line length='medium' />
													<Placeholder.Line length='short' />
												</Placeholder.Paragraph>
											</Placeholder>
										</Segment>
									</Grid.Column>

									<Grid.Column>
										<Segment raised>
											<Placeholder>
												<Placeholder.Header image>
													<Placeholder.Line />
													<Placeholder.Line />
												</Placeholder.Header>
												<Placeholder.Paragraph>
													<Placeholder.Line length='medium' />
													<Placeholder.Line length='short' />
												</Placeholder.Paragraph>
											</Placeholder>
										</Segment>
									</Grid.Column>
								</Grid>
							</div>
							<div>
								<Placeholder style={{ height: 150, width: 150 }}>
									<Placeholder.Image />
								</Placeholder>
								<p>Placeholder Image</p>
							</div>
						</ContainerFlex2>
					</ContainerFlex>
				</SizeDiv>
			</div>
		);
	}
}

export default CobaCoba;