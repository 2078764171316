import Loadable from "../../components/Loadable";

const BiodataPegawai = Loadable(() => import("./BiodataKepegawaian/BiodataPegawai"));
const DetilBiodataPegawai = Loadable(() => import("./BiodataKepegawaian/DetilBiodataPegawai"));
const RiwayatKepegawaian = Loadable(() => import("./RiwayatKepegawaian/RiwayatKepegawaian"));
const DetilRiwayatKepegawaian = Loadable(() => import("./RiwayatKepegawaian/DetilRiwayatKepegawaian"));
const TambahSuratKeterangan = Loadable(() => import("./RiwayatKepegawaian/TambahSuratKeterangan"));
const DetilSuratKeterangan = Loadable(() => import("./RiwayatKepegawaian/DetilSuratKeterangan"));
const DaftarArsipPegawai = Loadable(() => import("./ArsipPegawai/DaftarArsipPegawai"));
const DetilArsipBiodata = Loadable(() => import("./ArsipPegawai/DetilArsipBiodata"));
const DetilArsipRiwayat = Loadable(() => import("./ArsipPegawai/DetilArsipRiwayat"));
const DetilArsipSK = Loadable(() => import("./ArsipPegawai/DetilArsipSK"));

export default {
	DaftarArsipPegawai,
	DetilArsipBiodata,
	DetilArsipRiwayat,
	DetilArsipSK,

	DetilSuratKeterangan,
	TambahSuratKeterangan,
	DetilRiwayatKepegawaian,
	RiwayatKepegawaian,

	BiodataPegawai,
	DetilBiodataPegawai,
};