import Loadable from "../../components/Loadable";

const ProfilBiodata = Loadable(() => import("./ProfilBiodata"));
const ProfilKepegawaian = Loadable(() => import("./ProfilKepegawaian"));
const DetilSuratKeterangan = Loadable(() => import("./DetilSuratKeterangan"));

export default {
	DetilSuratKeterangan,
	ProfilKepegawaian,
	ProfilBiodata,
};