import _ from "lodash";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { Component } from 'react';
import { Button, Form, Message } from 'semantic-ui-react';

import { login } from '../../fetcher';
import { UserContext } from '../../context';

const ContainerHover = styled.p`
	:hover {
		cursor: pointer;
	}
`;

const ContainerForm = styled(Form)`
	@media only screen and (max-width: 768px) {
		padding-bottom: 4em;
	}
`;

class LoginForm extends Component {
	state = {
		formStatus: false,
		formValue: { username: null, password: null },
		isLoading: false,
		stepValue: "FormLogin",
	}


	//Handle Func
	handleChange = (e, props) => {
		const { formValue } = this.state
		const { name, value } = props
		this.setState({formStatus: false})
		return this.setState({formValue: _.assign({}, formValue, { [name]: value })});
	}
	handleChangeUsername = (e, props) => {
		const { formValue } = this.state
		const { name, value } = props
		const newValue = ((value || "").toString()).replace(/[\D\s._-]+/g, "");
		this.setState({formStatus: false})
		return this.setState({formValue: _.assign({}, formValue, { [name]: newValue })});
	}
	handleSubmit = async () => {
		this.setState({ isLoading: true })
		const session = this.context;
		const { formValue } = this.state;

		const response = await login(formValue);
		
		let formStatus = {};
		if (response.status_code <= 399) {
			await session.validateUser();
		} else {
			formStatus = { status: 'error', props: {error: true, size: 'tiny', header: 'Login Gagal', content: ''} }
			switch (response.error) {
				case "Kombinasi NIK dan Kata Sandi tidak sesuai": {
					formStatus.props = { error: true, size: 'tiny', header: 'Login Gagal', content: 'Kombinasi NIK dan Password yang Anda Masukkan tidak sesuai' };
					break;
				}
				case "Account is disabled": {
					formStatus.props = { error: true, size: 'tiny', header: 'Login Gagal', content: 'Mohon maaf, saat ini akun Anda berstatus nonaktif, mohon hubungi Admin untuk mengaktifkannya' };
					break;
				}
				default:
			}
		}

		this.setState({ isLoading: false, formStatus })
	}

	//React LifeCycle
	render() {
		const { formStatus, isLoading, formValue } = this.state
		const { handleClick } = this.props

		return (<ContainerForm>
			<Form.Field>
				<label>NIK</label>
				<Form.Input disabled={isLoading} name="username" value={formValue.username} placeholder="Masukkan NIK Anda" onChange={this.handleChangeUsername}/>
			</Form.Field>
			<Form.Field>
				<label>Password</label>
				<Form.Input disabled={isLoading} name="password" type="password" placeholder="Masukkan Password Anda" onChange={this.handleChange}/>
			</Form.Field>
			{_.get(formStatus, "props.error", false) && <div style={{marginBottom: "1em"}}><Message negative {..._.omit(_.get(formStatus, "props", {}), ["error"])} /></div>}
			<Form.Field style={{display: "flex"}}><ContainerHover style={{color: "#00923F", fontSize: "14px"}} onClick={() => handleClick({}, {name: "lupa-password"})}>Lupa password?</ContainerHover></Form.Field>
			<Form.Field><Button disabled={isLoading || _.isEmpty(formValue.username) || _.isEmpty(formValue.password)} loading={isLoading} primary fluid onClick={this.handleSubmit}>Masuk</Button></Form.Field>
		</ContainerForm>);
	}
}

LoginForm.propTypes = {
	handleClick: PropTypes.func
};

LoginForm.contextType = UserContext;

export default LoginForm;